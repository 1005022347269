import { useEffect, useState } from "react";
import moment from "moment";
import { Divider, Grid, Button } from "@mui/material";
import InputDisplay from "../../components/Inputs/InputDisplay";
import Modal from "../../components/Modal/Modal";
import LabelAsaas from "../../components/Labels/LabelAsaas";
import { displayMoney, displayNumber2 } from "../../services/displayValue";
import { dateBrl, dateEua, isDate } from "../../services/date";
import Acordion from "../../components/Acordion/Acordion";
import { AlertConfirm, AlertInfo } from "../../components/Alerts/Alert";
import TableSimple from "../../components/Tables/TableSimple";
import Input from "../../components/Inputs/Input";
import SimpleBackdrop from "../../components/Loading/SimpleBackdrop";
import { api, asaas, asaasUrl } from "../../services/api";
import isPermission from "../../services/permission";

export default function ModalInfoParcela({ open, setOpen, info, setInfo, reload }) {
    const [infoLogin] = useState(JSON.parse(sessionStorage.getItem(`infoLogin`)));
    const [openLoad, setOpenLoad] = useState(false);
    const [openModalReceber, setOpenModalReceber] = useState(false);
    const [openModalAlter, setOpenModalAlter] = useState(false);
    const [pagamento, setPagamento] = useState(dateEua());
    const [carnet, setCarnet] = useState([]);
    const columnsCarnet = [
        {
            id: 'parcela',
            numeric: false,
            label: 'PARCELA',
            format: displayNumber2
        },
        {
            id: 'vencimento',
            numeric: false,
            label: 'VENCIMENTO',
            format: dateBrl
        },
        {
            id: 'situacao',
            numeric: false,
            label: 'STATUS',
            format: () => "",
            component: LabelAsaas
        },
        {
            id: 'valor_parcela',
            numeric: true,
            label: 'VALOR R$',
            format: displayMoney
        },
        {
            id: 'pagamento',
            numeric: false,
            disablePadding: false,
            label: 'PAGAMENTO',
            format: (dt) => dt ? dateBrl(dt) : ""
        },
    ];

    function handleAlterParcela(row) {
        setInfo(row);
    }

    async function loadCarnet() {
        if (info && info.carnet_id_parcela) {
            const { carnet_id_parcela } = info;
            const response = await api.post(`/parcelas`, { carnet_id_parcela });
            if (response && response.status === 200 && response.data) {
                if (!response.data.error) setCarnet(response.data);
            }
        }
    }

    function openBoleto() {
        window.open(info.link_parcela);
    }

    async function openCarnet() {
        setOpenLoad(true);
        try {
            const data = {
                apiKey: infoLogin.chave_api_key,
                carnet_id: info.carnet_id_parcela
            };
            const response = await asaas.post(`/getCarnet`, data);
            if (response && response.status === 200) window.open(`${asaasUrl}/openCarnet/${data.carnet_id}`);
        } catch (error) {
            console.log(error);
            AlertInfo('error', 'FALHA NA OPERAÇÃO');
        }
        setOpenLoad(false);
    }

    async function handleReceber() {
        if(isPermission(62)){
            if(!isDate(pagamento)){
                AlertInfo('error', 'SELECIONE UMA DATA VALIDA');
            }else if(moment(pagamento).isAfter(dateEua())){
                AlertInfo('error', 'A DATA SELECIONADA NÃO PODE SER APÓS A ATUAL');
            }else{
                setOpenModalReceber(false);
                setOpenLoad(true);
                try {
                    const data = { 
                        apiKey: infoLogin.chave_api_key, 
                        id_transaction: info.id_transaction, 
                        paymentDate: pagamento, 
                        value: info.valor_parcela
                    };
                    const response = await asaas.post(`/receberParcela`, data);
                    console.log(response);
                    if(response && response.status === 200 && response.data){
                        const dataApi = {
                            id: { id_parcela: info.id_parcela },
                            data: { pagamento, situacao: response.data.status, valor_liquido: info.valor_parcela, taxa: 0 }
                        };
                        const resAPi = await api.post(`/updateparcela`, dataApi);
                        if(resAPi && resAPi.status === 200 && resAPi.data){
                            AlertInfo('success', 'DADOS GRAVADOS');
                            reload();
                            setOpen(false);
                        }
                    }
                } catch (error) {
                    console.log(error);
                    AlertInfo('error', 'FALHA NA CONEXÃO');
                }
                setOpenLoad(false);
            }
        }else{
            AlertInfo('info', 'USUARIO SEM PERMISSÃO');
        }
    }

    async function handleVencimento() {
        if(!isDate(pagamento)){
            AlertInfo('error', 'SELECIONE UMA DATA VALIDA');
        }else{
            setOpenModalAlter(false);
            setOpenLoad(true);
            try {
                const data = { 
                    apiKey: infoLogin.chave_api_key, 
                    id_transaction: info.id_transaction, 
                    dueDate: pagamento, 
                };
                const response = await asaas.post(`/updateParcela`, data);
                console.log(response);
                if(response && response.status === 200 && response.data){
                    const dataApi = {
                        id: { id_parcela: info.id_parcela },
                        data: { vencimento: pagamento }
                    };
                    const resAPi = await api.post(`/updateparcela`, dataApi);
                    if(resAPi && resAPi.status === 200 && resAPi.data){
                        AlertInfo('success', 'DADOS GRAVADOS');
                        reload();
                        setOpen(false);
                    }
                }
            } catch (error) {
                console.log(error);
                AlertInfo('error', 'FALHA NA CONEXÃO');
            }
            setOpenLoad(false);
        }
    }

    async function cancelParcela() {
        if(isPermission(63)){
            AlertConfirm('', 'CANCELAR PARCELA?', 'Essa ação não poderá ser desfeita').then(async resp => {
                if(resp.isConfirmed){
                    setOpenLoad(true);
                    try {
                        const data = { apiKey: infoLogin.chave_api_key, id_transaction:info.id_transaction };
                        const response = await asaas.post(`/deleteParcela`, data);
                        if(response && response.status === 200 && response.data){
                            if(response.data.deleted){
                                const dataApi = {
                                    id: { id_parcela: info.id_parcela },
                                    data: { situacao: 'CANCELED', pagamento }
                                };
                                const resAPi = await api.post(`/updateparcela`, dataApi);
                                if(resAPi && resAPi.status === 200 && resAPi.data){
                                    AlertInfo('success', 'DADOS GRAVADOS');
                                    reload();
                                    setOpen(false);
                                } 
                            }
                        }
                    } catch (error) {
                        console.log(error);
                        AlertInfo('error', 'FALHA NA CONEXÃO');
                    }
                    setOpenLoad(false);
                }
            });
        }else{
            AlertInfo('info', 'USUARIO SEM PERMISSÃO')
        }
    }

    function cancelCarnet() {
        if(isPermission(63)){
            const checkCarnet = carnet.filter(par => par.situacao !== 'PENDING' && par.situacao !== 'OVERDUE');
            if(checkCarnet.length > 0){
                AlertInfo('info', 'O CARNÊ JÁ POSSUI MOVIMENTAÇÃO E NÃO PODE SER MAIS CANCELADO');
            }else{
                AlertConfirm('', "CANCELAR CARNÊ EMITIDO", "A ação não poderá ser desfeita!").then(async res => {
                    if(res.isConfirmed){
                        setOpenLoad(true);
                        try {
                            const data = {
                                apiKey: infoLogin.chave_api_key,
                                carnet_id: info.carnet_id_parcela
                            };
                            const responseApi = await asaas.post(`/deleteCarnet`, data);
                            console.log(responseApi);
                            if(responseApi && responseApi.status === 200 && responseApi.data){
                                if(responseApi.data.deleted){
                                    const response = await api.delete(`/deletecarnet/${data.carnet_id}`);
                                    console.log(response);
                                    if(response && response.status === 200 && response.data){
                                        if(response.data.error){
                                            AlertInfo('error', response.data.message);
                                        }else if(response.data.success){
                                            AlertInfo('success', 'DADOS GRAVADOS');
                                            reload();
                                            setOpen(false);
                                        }
                                    }
                                }
                            }
                        } catch (error) {
                            console.log(error);
                            AlertInfo('error', 'FALHA NA CONEXÃO');
                        }
                        setOpenLoad(false);
                    }
                });
            }
        }else{
            AlertInfo('info', 'USUARIO SEM PERMISSAO')
        }
    }

    useEffect(() => {
        loadCarnet();
        // eslint-disable-next-line
    }, [info]);

    return (
        <Modal
            open={open}
            setOpen={setOpen}
            title={(info && info.id_parcela) ? `PARCELA ${displayNumber2(info.parcela)}/${displayNumber2(info.quantidade_parcelas)}` : ``}
            maxWidth="md"
        >
            <SimpleBackdrop open={openLoad} />

            <Modal
                open={openModalReceber}
                setOpen={setOpenModalReceber}
                title="RECEBER CONTA"
                maxWidth="xs"
            >
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Input
                            label="DATA PAGAMENTO"
                            value={pagamento}
                            setValue={setPagamento}
                            type="date"
                            upper={false}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Button
                            variant="contained"
                            fullWidth
                            color="success"
                            onClick={() => handleReceber()}
                        >
                            RECEBER CONTA
                        </Button>
                    </Grid>
                </Grid>
            </Modal>

            <Modal
                open={openModalAlter}
                setOpen={setOpenModalAlter}
                title="ALTERAR VENCIMENTO"
                maxWidth="xs"
            >
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Input
                            label="DATA PAGAMENTO"
                            value={pagamento}
                            setValue={setPagamento}
                            type="date"
                            upper={false}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Button
                            variant="contained"
                            fullWidth
                            color="info"
                            onClick={() => handleVencimento()}
                        >
                            ALTERAR VENCIMENTO
                        </Button>
                    </Grid>
                </Grid>
            </Modal>

            {info && info.id_parcela && (
                <Grid container spacing={0}>
                    <Grid item xs={12} sm={6} container spacing={0}>
                        <Grid item xs={12}>
                            <InputDisplay
                                label="NOME COMPLETO ALUNO(A)"
                                value={info.nome_aluno}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <InputDisplay
                                label="MATRICULA"
                                value={info.matricula_aluno}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <InputDisplay
                                label="TURMA"
                                value={`${info.nome_turma} (${info.turno_turma})`}
                            />
                        </Grid>
                    </Grid>

                    <Grid item xs={12} sm={6} container spacing={0}>
                        <Grid item xs={12}>
                            <InputDisplay
                                label="RESPONSAVEL"
                                value={info.responsavel}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <InputDisplay
                                label="CPF RESPONSAVEL"
                                value={info.cpf_responsavel}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <InputDisplay
                                label="TELEFONE RESPONSAVEL"
                                value={info.telefone_responsavel}
                            />
                        </Grid>
                    </Grid>

                    <Grid item xs={12}>
                        <Divider sx={{ marginBottom: 3 }} />
                    </Grid>

                    <Grid item xs={3}>
                        <InputDisplay
                            label="VALOR PARCELA"
                            value={`R$ ${displayMoney(info.valor_parcela)}`}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <InputDisplay
                            label="VENCIMENTO"
                            value={dateBrl(info.vencimento)}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <InputDisplay
                            label="DATA PAGAMENTO"
                            value={info.pagamento ? dateBrl(info.pagamento) : '-------'}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <LabelAsaas item={info.situacao} />
                    </Grid>

                    <Grid item xs={12}>
                        <Divider sx={{ marginBottom: 3 }} />
                    </Grid>

                    <Grid item xs={12} container spacing={3}>
                        <Grid item xs={12} sm={4}>
                            <Button
                                variant="contained"
                                fullWidth
                                onClick={openBoleto}
                            >
                                VISUALIZAR BOLETO
                            </Button>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            {(info.situacao === 'PENDING' || info.situacao === 'OVERDUE') && (
                                <Button
                                    variant="contained"
                                    fullWidth
                                    color="success"
                                    onClick={() => setOpenModalReceber(true)}
                                >
                                    RECEBER PARCELA
                                </Button>
                            )}
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            {(info.situacao === 'PENDING' || info.situacao === 'OVERDUE') && (
                                <Button
                                    variant="contained"
                                    fullWidth
                                    color="error"
                                    onClick={cancelParcela}
                                >
                                    CANCELAR PARCELA
                                </Button>
                            )}
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <Button
                                variant="contained"
                                onClick={openCarnet}
                                fullWidth
                            >
                                VISUALIZAR CARNÊ
                            </Button>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            {(info.situacao === 'PENDING' || info.situacao === 'OVERDUE') && (
                                <Button
                                    variant="contained"
                                    fullWidth
                                    color="info"
                                    onClick={() => setOpenModalAlter(true)}
                                >
                                    ALTERAR VENCIMENTO
                                </Button>
                            )}
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            {(info.situacao === 'PENDING' || info.situacao === 'OVERDUE') && (
                                <Button
                                    variant="contained"
                                    fullWidth
                                    color="error"
                                    onClick={cancelCarnet}
                                >
                                    CANCELAR CARNÊ
                                </Button>
                            )}
                        </Grid>
                    </Grid>

                    <Grid item xs={12}>
                        <br />
                        <Acordion title="RESUMO MENSALIDADES">
                            <Grid container spacing={0}>
                                <Grid item xs={12}>
                                    <TableSimple
                                        columns={columnsCarnet}
                                        rows={carnet}
                                        clickFunc={handleAlterParcela}
                                    />
                                </Grid>
                            </Grid>
                        </Acordion>
                    </Grid>
                    <Grid item xs={12}>
                        <br />
                        <Acordion title="DADOS COBRANÇA">
                            <Grid container spacing={0}>
                                <Grid item xs={12} sm={6}>
                                    <span>CODIGO: {info.id_transaction}</span>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <span>ID: {info.id_parcela}</span>
                                </Grid>
                            </Grid>
                        </Acordion>
                    </Grid>

                </Grid>
            )}
        </Modal>
    );
}